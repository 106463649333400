import { ExpandLessSharp, RadioButtonChecked, ArrowBack } from '@material-ui/icons';
import React, { Component } from 'react'
import FrappButton from '../../utilities/FrappButton';
import Dropdown from 'react-dropdown';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';

import PanelGroup from 'react-bootstrap/lib/PanelGroup'
import Panel from 'react-bootstrap/lib/Panel'
import FrappModal from "../../utilities/FrappModal"
import { constant, isArray } from 'lodash';
import constants from '../../../util/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXmark } from '@fortawesome/free-solid-svg-icons'
import ApiActions from '../../../actions/ApiActions';
import capitalise from '../../../helper/Capitalise';
export default class PreviewInteractiveScript extends Component {

    constructor(props) {
        super(props);
        this.state = {
            script: props.allScripts && props.allScripts.length > 0 && props.allScripts.find(script => script.defaultScript).script,
            currentScreen: {
                text: '',
                id: '',
                form: '',
                callerCue: '',
                actions: '',
                heading: ''
            },
            nextScreenId: '',
            btnName: '',
            nodeArray: [],
            arrowArray: [],
            edgeListArray: [],
            nodeListArray: [],
            nextStepOptions: [],
            prevScreensStack: [],
            initialNodesStack: [],
            faqs: this.getFaqFromScript(),
            faqActive: false,
            showModal: false,
            showLeadInfo: false,
            triggerActionModal: false,
            isNavigatorOpen: false,
            languages: [],
            scriptLanguageSelected: props.allScripts && props.allScripts.length > 0 && props.allScripts.find(script => {
                if (script.defaultScript)
                    return script.scriptLanguage
            }).scriptLanguage,
            callDisconnectOption: false,
            leadinfo: '',
            lastDispositionSelected: ''
        }
        this.scriptConstants = constants.scriptConstants
        this.inputRef = React.createRef()
    }

    goToNextScreen = (nextScreenId) => {
        const { nodeListArray, edgeListArray } = this.state
        this.setState({ prevEdges: this.state.nextStepOptions, prevNode: this.state.currentScreen })
        const nextScreen = nodeListArray.filter(node => node.id == nextScreenId)[0]
        this.setState({
            currentScreen: {
                text: nextScreen.data.label,
                id: nextScreen.id,
                form: nextScreen.data.form,
                callerCue: nextScreen.data.callerCueText,
                actions: nextScreen.data.actions,
                heading: nextScreen.data.heading
            }
        })
        const edgesFromNode = edgeListArray.filter(edge => edge.source == nextScreen.id)
        this.setState({ nextStepOptions: edgesFromNode })
        this.setState({ prevScreensStack: [...this.state.prevScreensStack, { edgesFromNode: edgesFromNode, prevScreen: nextScreen }] })
    }

    partition = (array, filter) => {
        let edge = [], node = [];
        array.forEach((e, idx, arr) => (filter(e, idx, arr) ? edge : node).push(e));
        return [edge, node];
    }

    getTheFirstScreenOfInteractiveScript = (edge, node) => {
        let { source, target, label } = edge
        this.setState({ nextScreenId: target })
        //get the first screen of IS
        this.setState({
            currentScreen: {
                text: node.data.label,
                id: node.id,
                callerCue: node.data.callerCueText,
                form: node.data.form,
                actions: node.data.actions,
                heading: node.data.heading
            }
        })
    }

    partitionArrayOfObjects = (arrayOfObj) => {
        const [edgeList, nodeList] = this.partition(arrayOfObj, e => e.source);
        return { edgeList, nodeList }
    }

    componentDidMount() {
        const {teleprojectdata: {id: projectId}} = this.props
        ApiActions.getLeadsInfo({
            teleproject: projectId
        }).then(resp => {
            if(resp && resp.length > 0) this.setState({leadinfo: resp[0].data})
        }).catch(error => console.error(error))

        let { script } = this.state
        let languageArray = this.props.allScripts.map(script => {
            return script.scriptLanguage
        })
        this.setState({ languages: [...languageArray] })
        if (!script || script.length <= 0) {
            return false
        }
        const { edgeList, nodeList } = this.partitionArrayOfObjects(script)
        if (edgeList.length <= 0 || nodeList.length <= 0) {
            return false
        }
        this.setState({ edgeListArray: edgeList, nodeListArray: nodeList })
        const startNodePresent = nodeList.filter(node => node.data.heading === this.scriptConstants.START)[0]
        const introductionNode = startNodePresent ? startNodePresent : nodeList.filter(node => node.data.heading === this.scriptConstants.INTRODUCTION)[0]
        const edgesFromFirstNode = edgeList.filter(edge => edge.source == introductionNode.id)
        this.setState({ nextStepOptions: edgesFromFirstNode })
        if (edgesFromFirstNode.length <= 0 || !introductionNode) {
            return false
        }
        this.getTheFirstScreenOfInteractiveScript(edgesFromFirstNode[0], introductionNode)
    }

    navigateToPrevScreen = () => {
        const { prevNode, prevEdges, nextStepOptions, nodeListArray, edgeListArray, currentScreen, nextScreenId } = this.state
        const goToPrevScreen = edgeListArray.filter(each => each.target == currentScreen.id)[0]
        if (!goToPrevScreen) {
            return false
        }
        this.setState({
            currentScreen: {
                ...this.state.currentScreen,
                form: '',
                callerCue: ''
            }
        })
        const edgesFromNode = edgeListArray.filter(edge => edge.source == goToPrevScreen.source)
        const prevScreen = nodeListArray.filter(each => each.id == goToPrevScreen.source)
        let originArray = this.state.prevScreensStack
        originArray.pop()
        if (originArray.length > 0) {
            let extractLastElement = originArray[originArray.length - 1]
            this.setState({ nextStepOptions: extractLastElement.edgesFromNode })
            this.setState({
                currentScreen: {
                    text: extractLastElement.prevScreen.data.label,
                    id: extractLastElement.prevScreen.id,
                    form: extractLastElement.prevScreen.data.form,
                    callerCue: extractLastElement.prevScreen.data.callerCueText,
                    actions: extractLastElement.prevScreen.data.actions,
                    heading: extractLastElement.prevScreen.data.heading
                }
            })
        } else {
            const edgesFromNode = edgeListArray.filter(edge => edge.source == goToPrevScreen.source)
            const prevScreen = nodeListArray.filter(each => each.id == goToPrevScreen.source)
            this.setState({ nextStepOptions: edgesFromNode })
            this.setState({
                currentScreen: {
                    text: prevScreen[0].data.label,
                    id: prevScreen[0].id,
                    form: prevScreen[0].data.form,
                    callerCue: prevScreen[0].data.callerCueText,
                    actions: prevScreen[0].data.actions,
                    heading: prevScreen[0].data.heading
                }
            })
        }
    }

    faqActive = () => {
        this.setState({ faqActive: true })
    }

    switchLang = (name) => {
        this.setState({ scriptLanguageSelected: name, faqs: this.getFaqFromScript(name)})
        const { faqs, currentScreen, nextScreenId, btnName, nextStepOptions } = this.state
        let extractedLangScript = this.props.allScripts.filter(script => {
            if (script.scriptLanguage == name)
                return script
        })[0].script

        this.setState({ script: extractedLangScript })
        const { edgeList, nodeList } = this.partitionArrayOfObjects(extractedLangScript)
        this.setState({ edgeListArray: edgeList, nodeListArray: nodeList })

        let currentNode = nodeList.filter(sc => sc.id == currentScreen.id)[0]
        let currentEdges = edgeList.filter(edge => edge.source == currentScreen.id)
        this.setState(
            {
                nextStepOptions: currentEdges
            }
        )
        this.setState({
            currentScreen: {
                text: currentNode.data.label,
                id: currentNode.id,
                form: currentNode.data.form,
                callerCue: currentNode.data.callerCueText,
                actions: currentNode.data && currentNode.data.actions,
                heading: currentNode.data.heading,
                id: currentNode.id
            }
        })
        //change the prevScreenStack to the selected langauge
        this.state.prevScreensStack.map(each => {
            let getnode = nodeList.filter(e => e.id == each.prevScreen.id)
            let getEdges = edgeList.filter(e => e.id == each.edgesFromNode[0].id)
            each.edgesFromNode = getEdges
            each.prevScreen = { ...getnode[0] }
            return each
        })
    }

    showTriggerActions = () => {
        let selectedAction;
        return (
            <FrappModal
                onCloseButton={true}
                className="script-language-modal"
                show={this.state.triggerActionModal}
                bsSize='small'
                closeButtonClicked={() => {
                    this.setState({ triggerActionModal: false })
                }}
            >
                <div className="script-language-modal-wrapper">
                    <div className="heading" style={{ fontWeight: '600' }}>Tigger Action</div>
                    {
                        this.state.isNavigatorOpen ?
                            isArray(this.state.initialNodesStack[0]) ?
                                this.state.initialNodesStack[0].map((action, i) => {
                                    return (
                                        <p key={i} className='option-icon-wrapper'>
                                            <input type="radio" id={`${action.title}-${i}`} value={action.title} name="radio-group"
                                            // checked={lang == this.state.scriptLanguageSelected ? true : false} 
                                            // onChange={(e) => selectedAction = this.state.currentScreen.actions.find(action => action.title == e.target.value)}
                                            />
                                            <label htmlFor={`${action.title}-${i}`}>{action.title}</label>
                                        </p>
                                    )
                                })
                                : null
                            :
                            this.state.currentScreen.actions && this.state.currentScreen.actions.length > 0
                            && this.state.currentScreen.actions.map((action, i) => {
                                return (
                                    <p key={i} className='option-icon-wrapper'>
                                        <input type="radio" id={`${action.title}-${i}`} value={action.title} name="radio-group"
                                        // checked={lang == this.state.scriptLanguageSelected ? true : false} 
                                        // onChange={(e) => selectedAction = this.state.currentScreen.actions.find(action => action.title == e.target.value)}
                                        />
                                        <label htmlFor={`${action.title}-${i}`}>{action.title}</label>
                                    </p>
                                )
                            })
                    }
                    <div className='footer-btn-wrapper'><FrappButton className="choose-lang-btn"
                        handler={() => this.setState({ triggerActionModal: false })}>CONFIRM</FrappButton></div>
                </div>
            </FrappModal>
        )
    }
    showLanguageOptions = () => {
        return (
            <FrappModal
                onCloseButton={true}
                className="script-language-modal"
                show={this.state.showModal}
                bsSize='small'
                closeButtonClicked={() => {
                    this.setState({ showModal: false })
                }}
            >
                <div className="script-language-modal-wrapper">
                    <div className="heading" style={{ fontWeight: '600' }}>Choose Script Language</div>
                    {
                        this.state.languages.map((lang, i) => {
                            return (
                                <p key={i} className='option-icon-wrapper'>
                                    <input type="radio" id={`${lang}-${i}`} value={lang} name="radio-group"
                                        checked={lang == this.state.scriptLanguageSelected ? true : false}
                                        onChange={(e) => this.switchLang(e.target.value)}
                                    />
                                    <label htmlFor={`${lang}-${i}`}>{lang}</label>
                                </p>
                            )
                        })
                    }
                    <div className='footer-btn-wrapper'><FrappButton className="choose-lang-btn" handler={() => this.setState({ showModal: false })}>Okay</FrappButton></div>
                </div>
            </FrappModal>
        )
    }

    showLeadsInfo = () => {
        const {leadinfo} = this.state
        return (
            <FrappModal
                onCloseButton={true}
                className="script-language-modal"
                show={this.state.showLeadInfo}
                bsSize='small'
                closeButtonClicked={() => {
                    this.setState({ showLeadInfo: false })
                }}
            >
                <div className="heading" style={{ fontWeight: '600' }}>Call Details</div>
                <div className="script-language-modal-wrapper">
                    {
                        leadinfo ? Object.entries(leadinfo).map(([key, value]) => {
                            return  <>
                            <div className='leads-details'>
                            <div>{capitalise(key)}</div>
                            <div>{value}</div>
                            </div>
                        </>
                        }) : 
                        <>
                        <div className='leads-details'>
                        <div>Lead ID</div>
                        <div>ad8b421ea28499edea2bc87205fada8f</div>
                        </div>
                        <div className='leads-details'>
                        <div>Name</div>
                        <div>Rahul</div>
                        </div>
                        <div className='leads-details'>
                        <div>City</div>
                        <div>Mumbai</div>
                    </div>
                        </>
                    }
                </div>
            </FrappModal>
        )
    }

    convertToSmallCase = (text) => {
        return text.trim().toLowerCase()
    }

    showNavigator = () => {
        this.setState({ faqActive: false })
        const getAllInitialNodes = [...this.state.nodeListArray].filter(e => e.initialNode).map(e => {
            return {
                'label': e.data.heading,
                'id': e.id
            }
        })

        const combinedArrayOfTriggerActn = this.state.nodeListArray.map(node => {
            if (node.data && node.data.actions)
                return node.data.actions
        }).filter(Boolean).flat()

        this.setState({ isNavigatorOpen: true })
        //setting priority for the initial nodes
        let prioritizedNodes = [...getAllInitialNodes].map(each => {
            switch (this.convertToSmallCase(each.label)) {
                case this.convertToSmallCase(this.scriptConstants.START):
                    return { ...each, priority: 0 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.INTRODUCTION):
                    return { ...each, priority: 1 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.SCHEDULE_CALL_BACK):
                    return { ...each, priority: 2 }
                    break;
                case this.convertToSmallCase(`${this.scriptConstants.SCHEDULE_CALL_BACK} Flow`):
                    return { ...each, priority: 2 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.NOT_INTERESTED):
                    return { ...each, priority: 3 }
                    break;
                case this.convertToSmallCase(`${this.scriptConstants.NOT_INTERESTED} Flow`):
                    return { ...each, priority: 3 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.QUICK_PITCH):
                    return { ...each, priority: 4 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.MAIN_PITCH):
                    return { ...each, priority: 5 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.WRONG_NUMBER):
                    return { ...each, priority: 6 }
                    break;
                case this.convertToSmallCase(`${this.scriptConstants.WRONG_NUMBER} Flow`):
                    return { ...each, priority: 6 }
                    break;
                case this.convertToSmallCase(this.scriptConstants.LANG_BARRIER):
                    return { ...each, priority: 7 }
                    break;
                case this.convertToSmallCase(`${this.scriptConstants.LANG_BARRIER} Flow`):
                    return { ...each, priority: 7 }
                    break;
                default:
                    return { ...each, priority: 'unknown' }
                    break;
            }
        })

        //crating a template to following the order of priority for rearranging the intial nodes
        let sort = [0, 1, 2, 3, 4, 5, 6, 7],
            defaultValue = Infinity,
            sortObj = {},
            rearrangedNodesOnPriority = [...prioritizedNodes]
        sort.forEach(function (a, i) {
            sortObj[a] = i + 1;
        });
        //rearranging the priortized initial nodes based on the template
        rearrangedNodesOnPriority.sort(function (a, b) {
            return (sortObj[a.priority] || defaultValue) - (sortObj[b.priority] || defaultValue);
        });

        let isClosingNode = rearrangedNodesOnPriority.findIndex(e => e.label == this.scriptConstants.CLOSING)
        if (isClosingNode !== -1) {
            rearrangedNodesOnPriority.push(rearrangedNodesOnPriority.splice(isClosingNode, 1)[0]);
        }

        if (combinedArrayOfTriggerActn && combinedArrayOfTriggerActn.length > 0) {
            rearrangedNodesOnPriority.splice(0, 0, combinedArrayOfTriggerActn)
        }
        this.setState({ initialNodesStack: this.checkIfOutcomeDisabled(rearrangedNodesOnPriority) })
    }

    filterFaq = (value) => {
        if (!value) {
            this.setState({ faqs: this.getFaqFromScript(this.state.scriptLanguageSelected) });
            return false;
        }
        const originalFaqs = this.getFaqFromScript(this.state.scriptLanguageSelected);
        value = value.trim().toLowerCase();

        const results = originalFaqs.filter(faq => {
            return Object.keys(faq).some(key => {
                if(key == 'question' || key == 'answer'){
                    const fieldValue = faq[key];
                    return typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(value);
                }
                
            });
        });
        this.setState({
            faqs: results.length > 0 ? results : []
        });
    }

    closeSearch = () => {
        if(this.inputRef && this.inputRef.current) this.inputRef.current.value = ''
        this.setState({faqs: this.getFaqFromScript(this.state.scriptLanguageSelected)})
    }

    formatText = (text) => {
        let inputText = text
        let pattern = /\*([^*]*[^* ])\*/g;
        // let pattern = /\*\s([^*]*[^* ])\s*\*/g;
        // let pattern = /\*\s*([^*]*[^*\s])\s*\*/g;
        let replacement = `<span class='highlight'>$1</span>`
        let highlightWords = inputText.replace(pattern, replacement);
        return highlightWords
    }
    
    getFaqFromScript = (language) => {
        const activeScript = this.props.allScripts.find(item => {
            if(language){
                if(item.scriptLanguage == language){
                    return item
                }
            }else{
                if(item.defaultScript){
                    return item
                }
            }
        })
        const faqs = activeScript && activeScript.faqs ? activeScript.faqs : this.props.faqs ? this.props.faqs : [];
        return faqs.map((faq, index) => ({
            ...faq,
            originalIndex: index + 1
        }));
    }

    checkIfOutcomeDisabled = (initialNodeStack) => {
        const { teleprojectdata } = this.props
        let outcomes = teleprojectdata && teleprojectdata.outcomes && teleprojectdata.outcomes.length > 0
        ? teleprojectdata.outcomes : []; 

        let disabledOutcomes =  outcomes && outcomes.filter(out => 
            out.hasOwnProperty("allowed") && out.allowed == "none"
        ).map(out => out.title)

        let filteredStackWithoutDisabledOutcomes = initialNodeStack.filter(each => {
            if(each.label){
                let removeFlowWord = each.label.replace(/flow/ig, "").trim()
                if(disabledOutcomes && !disabledOutcomes.includes(removeFlowWord))
                return each
            }else{
                return each
            }
        })
        return filteredStackWithoutDisabledOutcomes
    }

    render() {
        const { faqs, currentScreen, nextScreenId, btnName, initialNodesStack, nextStepOptions } = this.state
        const { projectTitle } = this.props;
        const { teleprojectdata } = this.props
        const outcomes = teleprojectdata && teleprojectdata.outcomes && teleprojectdata.outcomes.length > 0
        ? teleprojectdata.outcomes : [];
        const callDisconnectioOutcome = ['Redial', 'Busy or Improper Response', 'Voicemail']
        const callNotConnectedOutcome = ['Not Connected']
        let disconnectOutcomes = outcomes.filter(out => callDisconnectioOutcome.includes(out.title))
        let notConnectOutcome = outcomes.find(out => callNotConnectedOutcome.includes(out.title))
        const formatDiscconectedOutcomeName = (title) => {
            if(title == 'Redial') return 'Instant Redial'
            else return title
        }
        return (
            <>
                <div className='scriptPreview'>
                    {
                        !this.state.faqActive && !this.state.isNavigatorOpen ?
                            <div className='header'>
                                <div className='back'>
                                    <span onClick={() => this.navigateToPrevScreen()} style={{ cursor: 'pointer' }}><ArrowBack fontSize="large" /></span>
                                </div>
                                <div className="title">
                                    {projectTitle}
                                </div>
                                <div className="options">
                                <img src={require('../../../assets/images/Icons_user.svg')} style={{ cursor: 'pointer', width: '25px' }} 
                                onClick={() => this.setState({ showLeadInfo: true })}/>
                                    <img onClick={() =>
                                        this.setState({ showModal: true })
                                    } src={require('../../../assets/images/Icons_language.svg')} style={{ width: '25px', cursor: 'pointer' }} />
                                    {faqs && faqs.length > 0 ? <img src={faqs && faqs.length > 0 ? require('../../../assets/images/FAQ.svg') : ''} style={{ cursor: 'pointer', width: '25px' }} onClick={faqs.length ? this.faqActive : undefined}  /> : null}</div>
                            </div>
                            :
                            this.state.isNavigatorOpen ?
                                <div className='header'>
                                    <div className='back'>
                                        <span onClick={() => {
                                            this.setState({ isNavigatorOpen: false, callDisconnectOption: false  })
                                            this.closeSearch()
                                        }} style={{ cursor: 'pointer' }}><ArrowBack fontSize="large" /></span>
                                    </div>
                                    <div className="title center">
                                        {`Navigation`}
                                    </div>
                                </div> :
                                <div className='header'>
                                    <div className='back'>
                                        <span onClick={() => {
                                            this.setState({ faqActive: false, callDisconnectOption: false })
                                            this.closeSearch()
                                        }} style={{ cursor: 'pointer' }}><ArrowBack fontSize="large" /></span>
                                    </div>
                                    <div className="title">
                                        {`FAQ's`}
                                    </div>
                                    <div className="options">
                                        {<img src={this.props.faqs && this.props.faqs.length > 0 ? require('../../../assets/images/FAQ.svg') : ''} style={{ cursor: 'pointer', width: '25px' }} onClick={faqs.length ? this.faqActive : undefined} alt='faq'/>}</div>
                                </div>
                    }
                    {!this.state.faqActive ?
                        this.state.isNavigatorOpen ?
                            <div className="content">
                                <div className="navigator-wrapper" onClick={() => this.setState({callDisconnectOption: false})}>
                                    <div className='outcome'>
                                        {
                                            initialNodesStack.map((option, i) => {
                                                if (isArray(initialNodesStack[i])) {
                                                    return <div className='live-action-wrapper'>
                                                        <FrappButton className="live-action-btn"
                                                            handler={() => this.setState({ triggerActionModal: true })}
                                                        ><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')} />{'Trigger Action'}</FrappButton>
                                                        <span className='divider'></span>
                                                    </div>
                                                } else
                                                    return <FrappButton key={i} handler={() => {
                                                        this.setState({ isNavigatorOpen: false })
                                                        this.goToNextScreen(option.id)
                                                    }} className="submit app">{option.label}</FrappButton>
                                            }
                                            )
                                        }
                                    </div>
                                </div>
                                {/* show options on call disconnection */}
                                {this.state.callDisconnectOption && <div className='call-disconnected-option' onClick={()=>this.setState({callDisconnectOption: false})}>
                                <div className="navigator-wrapper-disconnect">
                                <div className='outcome'>
                                {disconnectOutcomes.length > 0 ? (
                                    disconnectOutcomes.map((item, index) => (
                                        item.allowed && item.allowed == 'none' ? null : <FrappButton key={`disconnect-${index}`} className="submit app-blue">
                                        {formatDiscconectedOutcomeName(item.title)}
                                        </FrappButton>
                                    ))
                                    ) : (
                                    <span>No disconnect options available</span> 
                                    )}
                                </div>
                                </div>
                                </div>}

                                {initialNodesStack && initialNodesStack.length > 0 ? <div className='call-disconnected'>
                                    {notConnectOutcome && notConnectOutcome.allowed == 'none' ? null : <FrappButton key={'notconnected'} className="submit app"
                                    >{'Not Connected'}</FrappButton>}
                                    <FrappButton key={'disconnected'} handler={() => {
                                        this.setState({callDisconnectOption: true})
                                    }} className="submit app red-button">{'Call Disconnected'}</FrappButton>
                                    <span className='grey-text'>Select this if call gets disconnected</span>
                                </div> : null}
                            </div> :
                            <div className='content'>
                                <div className='para'>
                                    <p>{!currentScreen.text ? 'No script got from backend api / or no proper connection between nodes and edges'
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: this.formatText(currentScreen.text) }} />
                                    }</p>
                                    {currentScreen.callerCue ? <p className='callerCue'>{currentScreen.callerCue}</p> : null}
                                    {
                                        currentScreen.form && currentScreen.form.length > 0 ?
                                            currentScreen.form.map((elem, i) => {
                                                if (elem.inputType == 'text') {
                                                    return (<div className='formQuestions' key={i}>
                                                        <FormGroup>
                                                            <p>{elem.label} {elem.isOptional ? <span className='optional'>(optional)</span> : null}</p>
                                                            <FormControl
                                                                name="text"
                                                                style={{ backgroundColor: '#001B30' }}
                                                            />
                                                        </FormGroup>
                                                    </div>)
                                                } else if (elem.inputType == 'singleselect' || elem.inputType == 'multiselect') {
                                                    let options = elem.options.map(each => {
                                                        if (each.optionText)
                                                            return {
                                                                label: each.optionText,
                                                                value: each.optionText
                                                            }
                                                    }).filter(Boolean)
                                                    return (<div className='formQuestions' key={i}>
                                                        <p>{elem.label} {elem.isOptional ? <span className='optional'>(optional)</span> : null}</p>
                                                        {elem.inputType == 'singleselect' ? 
                                                        <Dropdown options={options}
                                                            placeholder="Select"
                                                            style={{ backgroundColor: '#001B30' }}
                                                        /> :
                                                        <div className='multi-select-option'>
                                                        {
                                                            options.map(item => {
                                                                return <div className='option'>
                                                                    <label><input type="checkbox" /><span>{item.label}</span></label>
                                                                </div>
                                                            })
                                                        }
                                                        </div>
                                                        }
                                                    </div>)
                                                } else if (elem.inputType == 'date-time') {
                                                    return (<div className='formQuestions' key={i}>
                                                        <FormGroup>
                                                            <p>{elem.label} {elem.isOptional ? <span className='optional'>(optional)</span> : null}</p>
                                                            <FormControl
                                                                name="text"
                                                                style={{ backgroundColor: '#001B30' }}
                                                            />
                                                        </FormGroup>
                                                    </div>)
                                                } else if (elem.inputType == 'date') {
                                                    return (<div className='formQuestions' key={i}>
                                                        <FormGroup>
                                                            <p>{elem.label} {elem.isOptional ? <span className='optional'>(optional)</span> : null}</p>
                                                            <FormControl
                                                                name="text"
                                                                style={{ backgroundColor: '#001B30' }}
                                                            />
                                                        </FormGroup>
                                                    </div>)
                                                } else if (elem.inputType == 'time') {
                                                    return (<div className='formQuestions' key={i}>
                                                        <FormGroup>
                                                            <p>{elem.label} {elem.isOptional ? <span className='optional'>(optional)</span> : null}</p>
                                                            <FormControl
                                                                name="text"
                                                                style={{ backgroundColor: '#001B30' }}
                                                            />
                                                        </FormGroup>
                                                    </div>)
                                                }
                                            }) : null
                                    }
                                </div>
                                <div className="outcomes-wrapper">
                                    <div className='outcome'>
                                        {
                                            currentScreen.actions && currentScreen.actions.length > 0 ?
                                                <div className='live-action-wrapper'>
                                                    <FrappButton className="live-action-btn"
                                                        handler={() => this.setState({ triggerActionModal: true })}
                                                    ><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')} />{'Trigger Action'}</FrappButton>
                                                    <span className='divider'></span>
                                                </div>
                                                : null
                                        }
                                        {
                                            nextStepOptions.map((option, i) =>
                                                <FrappButton key={i} handler={() => {
                                                    if(option.data && option.data.outcome){
                                                        this.setState({lastDispositionSelected: option.data.outcome})
                                                    }
                                                    this.goToNextScreen(option.target)
                                                }} className="submit app">{option.label ? option.label : option.data.outcome}</FrappButton>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        :
                        (
                            <div className="content">
                                <div className='faq-wrapper'>
                                <input type='search' className='faq-search'
                                onChange={(e) => this.filterFaq(e.target.value)} placeholder={`Search FAQ's`} ref={this.inputRef}/>
                                    <FontAwesomeIcon icon={faXmark} className='clear-icon' onClick={()=> this.closeSearch()}/>
                                    <PanelGroup accordion id="accordion-example">
                                        {
                                            faqs.map((faq, i) => {
                                                return (
                                                    <Panel eventKey={i + 1} key={i}>
                                                        <Panel.Heading>
                                                            <Panel.Title toggle><span>{`${faq.originalIndex || i + 1}. ${faq.question}`}</span></Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body collapsible id='answer'>{faq.answer}</Panel.Body>
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </PanelGroup>
                                </div>
                            </div>
                        )
                    }
                     {
                    currentScreen.heading == 'Ending' && this.state.lastDispositionSelected 
                    && !this.state.faqActive && !this.state.isNavigatorOpen ? <FrappButton className="final_disposition">{`Dispose as ${this.state.lastDispositionSelected} outcome`}</FrappButton> : null
                    }
                    {!this.state.isNavigatorOpen && <div className="navigation-footer" onClick={() => this.showNavigator()}>Navigation</div>}
                </div>
                {this.showLanguageOptions()}
                {this.showLeadsInfo()}
                {this.showTriggerActions()}
            </>
        )
    }
}